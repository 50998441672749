import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { catchError, distinctUntilChanged, lastValueFrom, map, of, shareReplay } from 'rxjs';
import {
    NotificationDetailModalComponent,
    NotificationDetailMode
} from 'src/app/components/modals/hotlines/notification-detail-modal/notification-detail-modal.component';
import { MultiSelectIconOption } from 'src/app/components/search/search-multiselect-icon/search-multiselect-icon.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';

type ErvaringNadien = 'doorgegaan' | 'gestopt' | 'aangepast' | 'overgestapt';
type Gender = 'M' | 'F';
type Sort = { code: string; dir: 'asc' | 'desc' };
type Search = {
    term?: string;
    experiences?: string[];
    experiences_after?: ErvaringNadien[];
    gender?: Gender[];
    age?: {
        age_code: string;
        age_from?: string;
        age_until?: string;
    };
    approved?: string[];
};
export type Notification = {
    id: string;

    ervaringIndicator: {
        type: string;
        iconPath: string;
    };
    medicijn: {
        type: string;
        value: string;
    };
    ervaringDescr: {
        type: string;
        value: string;
    };
    createTS: {
        type: string;
        value: string;
    };
    actions: {
        type: 'actions';
        actions: any;
    };
};
@Component({
    selector: 'app-hotline',
    templateUrl: './hotline.component.html',
    styleUrl: './hotline.component.scss'
})
export class HotlineComponent implements OnInit {
    configService = inject(ConfigService);
    helpersService = inject(HelpersService);
    searchService = inject(SearchService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    defaultService = inject(DefaultService);
    modalService = inject(NgbModal);
    toastr = inject(ToastrService);

    notificationDetailModal: NgbModalRef;
    appOverviewUrl: string;

    SEARCH: Search = {
        approved: ['active']
    };
    SEARCHED: any[] = [];
    SORT: Sort = { code: 'createTS', dir: 'desc' };
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    ready = signal<boolean>(false);
    loading = signal<boolean>(false);
    processingExport: boolean = false;

    experiences: MultiSelectIconOption[] = [
        {
            title: 'Verschikkelijk',
            renderTitle: false,
            iconPath: `/assets/img/icons/experience_score-1.png`,
            value: '1'
        },
        {
            title: 'Negatief',
            renderTitle: false,
            iconPath: `/assets/img/icons/experience_score-2.png`,
            value: '2'
        },
        {
            title: 'Neutraal',
            renderTitle: false,
            iconPath: `/assets/img/icons/experience_score-3.png`,
            value: '3'
        },
        {
            title: 'Positief',
            renderTitle: false,
            iconPath: `/assets/img/icons/experience_score-4.png`,
            value: '4'
        }
    ];
    experiences_after = [
        {
            title: 'Doorgegaan',
            value: 'doorgegaan'
        },
        {
            title: 'Gestopt',
            value: 'gestopt'
        },
        {
            title: 'Aangepast',
            value: 'aangepast'
        },
        {
            title: 'Overgestapt',
            value: 'overgestapt'
        }
    ];
    gender = [
        {
            title: 'Man',
            value: 'M'
        },
        {
            title: 'Vrouw',
            value: 'F'
        }
    ];
    status = [
        {
            title: 'Goed te keuren',
            value: 'pending'
        },
        {
            title: 'Actief',
            value: 'active'
        }
    ];
    age_codes: { title: string; value: string }[] = [
        {
            title: 'Exact',
            value: 'exact'
        },
        {
            title: 'Minimum',
            value: 'minimum'
        },
        {
            title: 'Maximum',
            value: 'maximum'
        },
        {
            title: 'Tussen',
            value: 'range'
        }
    ];

    tableHeads: {
        name: string;
        code: string;
        sortable: boolean;
        width?: string;
    }[] = [
        { name: 'Ervaring', code: 'ervaringIndicator', sortable: true, width: '10%' },
        { name: 'Medicijn', code: 'medicijn', sortable: true, width: '30%' },
        { name: 'Melding', code: 'ervaringDescr', sortable: true, width: '45%' },
        { name: 'Aangemaakt op', code: 'createTS', sortable: true, width: '15%' },
        { name: '', code: 'actions', sortable: false, width: '0.1%' }
    ];
    data: Notification[] = [];

    urlQueryParams$ = this.route.queryParamMap.pipe(
        map((paramMap: ParamMap) => {
            const formattedParams: { query?: string; page?: string; sort?: string } = {
                query: paramMap.get('query'),
                page: paramMap.get('page'),
                sort: paramMap.get('sort')
            };

            return formattedParams;
        }),
        distinctUntilChanged((prev, curr) => {
            return prev.page === curr.page && prev.query === curr.query && prev.sort === curr.sort;
        }),
        map((params: { query: string; page: string; sort?: string }) => {
            this.loading.set(true);
            const searchObject: { query?: Search; page?: number; sort?: Sort } = {};
            if (params.query) {
                const query = JSON.parse(params.query) ?? undefined;
                const age = {
                    age_code: query?.age_code,
                    age_from: query?.age_from,
                    age_until: query?.age_until
                };
                delete query?.age_code;
                delete query?.age_from;
                delete query?.age_until;
                searchObject.query = {
                    ...query,
                    age: age
                };
            }
            if (params.page) searchObject.page = parseInt(params.page);
            if (params.sort) searchObject.sort = JSON.parse(params.sort) ?? undefined;

            this.startRow = (searchObject?.page || 1) * this.RPP - this.RPP;
            this.SORT = params?.sort ? JSON.parse(params?.sort) : this.SORT;
            if (searchObject.query) {
                this.SEARCH = searchObject.query;
            } else {
                this.initSearchObject();
            }

            return searchObject;
        }),

        catchError((error) => {
            this.loading.set(false);
            return of(); // Handle observable error by returning undefined
        }),
        shareReplay(1)
    );
    detailNotificationId$ = this.route.queryParamMap.pipe(
        map((paramMap: ParamMap) => {
            const id = paramMap.get('notificationID');
            return id ? id : undefined;
        }),
        distinctUntilChanged(),
        catchError((error) => {
            return of();
        }),
        shareReplay(1)
    );

    async ngOnInit(): Promise<void> {
        this.appOverviewUrl = this.configService.getConfig().appOverviewUrl;
        this.urlQueryParams$.subscribe(async (params) => {
            await this.getHotlines();
        });

        this.detailNotificationId$.subscribe((next) => {
            if (next) {
                // Dismiss any existing modal before opening a new one
                if (this.notificationDetailModal) {
                    this.notificationDetailModal.dismiss();
                    this.notificationDetailModal = null;
                }

                this.notificationDetailModal = this.modalService.open(NotificationDetailModalComponent, {
                    windowClass: 'main-modal detail-modal',
                    beforeDismiss: () => {
                        return (
                            this.notificationDetailModal.componentInstance.canExit &&
                            this.notificationDetailModal.componentInstance.canExit()
                        );
                    }
                });

                this.notificationDetailModal.dismissed.subscribe({
                    next: (next) => {
                        this.router.navigate([], {
                            queryParams: {
                                notificationID: undefined,
                                mode: undefined
                            },
                            queryParamsHandling: 'merge'
                        });
                        this.notificationDetailModal = null;
                    }
                });
                this.notificationDetailModal.closed.subscribe({
                    next: (next) => {
                        this.router.navigate([], {
                            queryParams: {
                                notificationID: undefined,
                                mode: undefined
                            },
                            queryParamsHandling: 'merge'
                        });
                        this.notificationDetailModal = null;
                        this.getHotlines();
                    }
                });
            } else {
                if (this.notificationDetailModal) {
                    this.notificationDetailModal.dismiss();
                    this.notificationDetailModal = null;
                }
            }
        });
    }

    setAgePeriod($event) {
        this.SEARCH.age = {
            age_code: $event.code
        };
        if ((!$event.from && !$event.until) || !$event.code) {
            delete this.SEARCH.age;
        } else {
            switch (this.SEARCH.age.age_code) {
                case 'exact':
                case 'minimum':
                    this.SEARCH.age.age_from = $event.from;
                    break;
                case 'range':
                    this.SEARCH.age.age_from = $event.from;
                    this.SEARCH.age.age_until = $event.until;
                    break;
                case 'maximum':
                    this.SEARCH.age.age_until = $event.until;
                    break;
            }
        }

        this.search();
    }

    async formatSearchIntoTags() {
        let age = undefined;

        if (this.SEARCH?.age) {
            switch (this.SEARCH.age.age_code) {
                case 'exact':
                    age = `${this.SEARCH.age.age_from} jaar`;
                    break;
                case 'minimum':
                    age = `Vanaf ${this.SEARCH.age.age_from} jaar`;
                    break;
                case 'maximum':
                    age = `Tot ${this.SEARCH.age.age_until} jaar`;
                    break;
                case 'range':
                    age = `${this.SEARCH.age.age_from} tot ${this.SEARCH.age.age_until} jaar`;
                    break;
                default:
                    break;
            }
        }

        const formattedSearch = {
            term: this.SEARCH?.term?.length ? [this.SEARCH?.term] : undefined,
            experiences: this.SEARCH?.experiences?.length
                ? this.experiences.filter((x) => this.SEARCH?.experiences?.includes(x.value))?.map((x) => x.title)
                : undefined,
            experiences_after: this.SEARCH?.experiences_after?.length
                ? this.experiences_after
                      .filter((x) => this.SEARCH?.experiences_after?.includes(x.value as ErvaringNadien))
                      ?.map((x) => x.title)
                : undefined,
            gender: this.SEARCH?.gender?.length
                ? this.gender.filter((x) => this.SEARCH?.gender?.includes(x.value as Gender))?.map((x) => x.title)
                : undefined,
            age: age ? [age] : undefined,
            approved: this.SEARCH?.approved?.length
                ? this.status.filter((x) => this.SEARCH?.approved?.includes(x.value))?.map((x) => x.title)
                : undefined
        };

        return await this.searchService.formatTags(formattedSearch, {});
    }

    async getHotlines() {
        this.loading.set(true);
        await this.formatSearchIntoTags().then((result) => {
            this.SEARCHED = result;
        });

        const approved = this.SEARCH?.approved?.length
            ? this.SEARCH?.approved[0] === 'active'
                ? true
                : false
            : undefined;
        const experiences = this.SEARCH.experiences?.map((x) => parseInt(x));

        const leeftijd = this.SEARCH?.age?.age_code === 'exact' ? parseInt(this.SEARCH.age.age_from) : undefined;
        const leeftijdMin =
            this.SEARCH?.age?.age_code !== 'exact' && this.SEARCH?.age?.age_from
                ? parseInt(this.SEARCH?.age.age_from)
                : undefined;
        const leeftijdMax =
            this.SEARCH?.age?.age_code !== 'exact' && this.SEARCH?.age?.age_until
                ? parseInt(this.SEARCH.age.age_until)
                : undefined;
        const notifications = await lastValueFrom(
            this.defaultService.notificationsGetNotifications(
                this.startRow,
                this.RPP,
                [`${this.SORT.code} ${this.SORT.dir}`],
                approved,
                this.SEARCH?.term,
                experiences,
                this.SEARCH?.experiences_after,
                this.SEARCH?.gender,
                leeftijd,
                leeftijdMin,
                leeftijdMax
            )
        );

        this.totalRows = notifications.rows;
        this.data = notifications?.data.map((notification) => {
            const actions = [];
            const editAction = {
                name: 'Wijzigen',
                code: 'edit',
                icon: 'pencil'
            };
            actions.push(editAction);
            const deleteAction = {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            };
            actions.push(deleteAction);

            return {
                ...notification,
                id: `${notification.id}`,
                ervaringIndicator: {
                    type: 'icon',
                    iconPath: `/assets/img/icons/experience_score-${notification.ervaringIndicator}.png`
                },
                medicijn: {
                    type: 'default',
                    value: notification.medicijn
                },
                ervaringDescr: {
                    type: 'default',
                    value: notification.ervaringDescr
                },
                createTS: {
                    type: 'default',
                    value: moment(notification.createTS).format('DD-MM-YYYY')
                },
                actions: {
                    type: 'actions',
                    actions: actions
                }
            } as Notification;
        });

        this.ready.set(true);
        this.loading.set(false);
    }

    // tableClick(item: any) {
    //     switch (item.type) {
    //         case 'actions':
    //             break;
    //         default:
    //             // this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
    //             break;
    //     }
    // }

    openDetail(notificationID: string, mode?: NotificationDetailMode) {
        // this.modal;
        this.router.navigate([], {
            queryParams: {
                notificationID: notificationID,
                mode: mode ? mode : 'view'
            },
            queryParamsHandling: 'merge'
        });
    }

    actionClick(item: Notification, action: string) {
        switch (action) {
            case 'edit':
                // this.openCreateUpdateModal(item.id, true);
                this.openDetail(item.id, 'edit');
                break;
            case 'delete':
                this.deleteNotification(parseInt(item.id));
                break;
        }
    }

    deleteNotification(id: number) {
        this.loading.set(true);

        this.defaultService.notificationsDeleteNotification(id).subscribe({
            next: (next) => {
                this.toastr.success('Melding werd succesvol verwijderd', 'Melding verwijderd');
                this.getHotlines();
            },
            error: (error) => {
                this.loading.set(false);
                this.toastr.error('Er liep iets mis bij het verwijderen van de melding', 'Melding verwijderen mislukt');
            }
        });
    }

    setStartRow(startRow: number) {
        this.startRow = startRow;
        this.search();
        document.documentElement.scrollTop = 0;
    }

    setSort(code: string, dir: 'asc' | 'desc') {
        this.SORT = { code: code, dir: dir };
        this.search();
        // this.getHotlines();
    }

    resetSearch() {
        this.initSearchObject();
        this.startRow = 0;
    }

    initSearchObject() {
        this.SEARCH = {
            approved: ['active']
        };
    }

    search = async (reset?: boolean, SEARCH?: any) => {
        if (reset) {
            this.resetSearch();
            this.router.navigate([], {
                queryParams: {
                    query: undefined,
                    page: undefined,
                    sort: undefined
                },
                queryParamsHandling: 'merge'
            });
            return;
        }

        SEARCH = this.formatSearchObject(SEARCH || this.SEARCH);

        this.router.navigate([], {
            queryParams: {
                query: JSON.stringify(SEARCH),
                page: (Math.ceil(this.startRow / this.RPP) + 1).toString(),
                sort: JSON.stringify(this.SORT)
            },
            queryParamsHandling: 'merge'
        });
        // }

        // this.getHotlines();
    };

    formatSearchObject(search: Search) {
        let SEARCH = { ...search };
        if (!SEARCH?.approved?.length) delete SEARCH.approved;

        SEARCH = {
            ...SEARCH,
            ...SEARCH.age
        };
        delete SEARCH.age;

        return SEARCH;
    }

    export() {
        this.processingExport = true;

        const approved = this.SEARCH?.approved?.length
            ? this.SEARCH?.approved[0] === 'active'
                ? true
                : false
            : undefined;
        const experiences = this.SEARCH.experiences?.map((x) => parseInt(x));

        const leeftijd = this.SEARCH?.age?.age_code === 'exact' ? parseInt(this.SEARCH.age.age_from) : undefined;
        const leeftijdMin =
            this.SEARCH?.age?.age_code !== 'exact' && this.SEARCH?.age?.age_from
                ? parseInt(this.SEARCH?.age.age_from)
                : undefined;
        const leeftijdMax =
            this.SEARCH?.age?.age_code !== 'exact' && this.SEARCH?.age?.age_until
                ? parseInt(this.SEARCH.age.age_until)
                : undefined;

        this.defaultService
            .notificationsExportNotifications(
                [`${this.SORT.code} ${this.SORT.dir}`],
                approved,
                this.SEARCH?.term,
                experiences,
                this.SEARCH?.experiences_after,
                this.SEARCH?.gender,
                leeftijd,
                leeftijdMin,
                leeftijdMax
            )
            .subscribe((next) => {
                this.helpersService.downloadBlob(next, `meldingen-${moment().format('YYYY-MM-DD')}.xlsx`);
                this.processingExport = false;
            });
    }
}
