<span class="close-modal-outside"><i (click)="dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div class="d-flex">

        <!-- Start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="ready">
                <div class="">
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">Popup detail</h1>

                        <div class="button-section-bottom">
                            <div class="h-100 d-flex align-items-center">
                                <button class="button tertiary" (click)="dismiss()" [class.disabled]="saving"><span>Annuleren</span></button>
                                <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                            </div>
                        </div>
                     
                    </div>
                </div>

                <div class="detail-modal-body">
                    <!-- content -->
                    <div class="col-md-2">
                        <!-- Active -->
                        <div class="display-content">
                            <app-field-radios 
                                class="d-block"
                                label="Popup actief?"
                                [model]="FORM.actief ? 'yes' : 'no'"
                                (modelChange)="FORM.actief = ($event === 'yes' ? true : false)"
                                [error]="validation?.actief"
                                [options]="yesNoOptions"
                                [optionWidth]="80"
                                [optionColumnWidth]=""
                            ></app-field-radios>
                        </div>
                    </div>
                    <div class="form-section" [class.disabled]="!FORM.active">
                        <div class="col-xl-6">
                            <!-- Titel -->
                            <div class="display-content">
                                <app-field-text
                                    [model]="FORM.titel" 
                                    (modelChange)="FORM.titel = $event; formChange();" 
                                    label="Titel"
                                    [error]="validation?.titel"
                                    placeholder="Titel"
                                ></app-field-text>
                            </div>
                        </div>
                        <div class="col-xl-12">
                            <!-- Tekst -->
                            <div class="display-content">
                                <app-field-textarea
                                    [model]="FORM.inhoud ?? ''" 
                                    (modelChange)="FORM.inhoud = $event; formChange();" 
                                    label="Tekst"
                                    [error]="validation?.inhoud"
                                    placeholder="Inhoud van de popup..."
                                ></app-field-textarea>
                            </div>
                        </div>
                        <div class="col-xl-12">
                            <!-- Knop tonen -->
                            <div class="display-content">
                                <app-field-radios 
                                    class="d-block"
                                    label="Knop tonen"
                                    [model]="FORM.toonKnop ? 'yes' : 'no'"
                                    (modelChange)="FORM.toonKnop = ($event === 'yes' ? true : false)"
                                    [error]="validation?.toonKnop"
                                    [options]="yesNoOptions"
                                    [optionWidth]="80"
                                    [optionColumnWidth]=""
                                ></app-field-radios>
                            </div>
                        </div>

                        <div>
                            <label class="display-label">Knop</label>
                            <div class="button-section" [class.saving]="!FORM.toonKnop" style="border: 1px solid #D7DADD; border-radius: 4px; padding: 24px;">
                                <div class="row display-row mw-1200-px">
                                    <div class="col-xl-6">
                                        <!-- Knop tekst -->
                                        <div class="display-content">
                                            <app-field-text
                                                [model]="FORM.knop?.titel" 
                                                (modelChange)="FORM.knop.titel = $event; formChange();" 
                                                label="Titel"
                                                [error]="validation?.knop?.titel"
                                                placeholder="Titel">
                                            </app-field-text>
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row mw-1200-px">
                                    <div class="col-xl-12">
                                        <!-- buttonLink -->
                                        <div class="display-content">
                                            <app-field-text
                                                [model]="FORM.knop?.link" 
                                                (modelChange)="FORM.knop.link = $event; formChange();" 
                                                label="Link"
                                                [error]="validation?.knop?.link"
                                                placeholder="Link">
                                            </app-field-text>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex gap-10">
                                    <!-- New tab -->
                                    <input type="checkbox" id="newTab" name="newTab" [(ngModel)]="FORM.knop.nieuwTabblad" (ngModelChange)="formChange()">
                                    <label for="newTab" class="newTab">Link in nieuw tablad openen</label>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <app-history
                    [createTS]="data.createTS"
                    [createUsername]="data.createUsername"
                    [editTS]="data.editTS"
                    [editUsername]="data.editUsername"
                ></app-history>
            </ng-container>
        </div>
    </div>
</div>