import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { DefaultService, MeldpuntNewsitemPayload } from 'src/app/utils/api';
export type NewsDetailMode = 'create' | 'edit' | 'view';
type NewsDetail = {
    titel: string;
    inhoud: string;
    afbeeldingUrl?: string;
    afbeelding?: File;
    gepubliceerd: boolean;
    homepage?: boolean;

    createTS?: string;
    editTS?: string;
    createUsername?: string;
    editUsername?: string;
};
@Component({
    selector: 'app-hotline-news-detail-modal',
    templateUrl: './hotline-news-detail-modal.component.html',
    styleUrl: './hotline-news-detail-modal.component.scss'
})
export class HotlineNewsDetailModalComponent implements OnInit {
    activeModal = inject(NgbActiveModal);
    route = inject(ActivatedRoute);
    router = inject(Router);
    defaultService = inject(DefaultService);
    toastr = inject(ToastrService);

    mode: NewsDetailMode = undefined;
    ready: boolean = false;
    loading: boolean = false;
    saving: boolean = false;
    submitting: boolean = false;
    changes: boolean = false;
    validation: any;

    id: string = undefined;
    data: NewsDetail;
    FORM: NewsDetail;

    confirmDelete = false;

    yesNoOptions = [
        { title: 'Ja', value: 'yes' },
        { title: 'Nee', value: 'no' }
    ];

    ngOnInit() {
        this.id = this.route.snapshot.queryParamMap.get('newsId');
        this.mode = this.route.snapshot.queryParamMap.get('mode') as NewsDetailMode;
        if (this.mode === 'create') this.createEmptyNewsitem();
        else this.getNewsItem(this.id);
    }

    createEmptyNewsitem() {
        this.data = {
            titel: undefined,
            inhoud: undefined,
            afbeelding: undefined,
            gepubliceerd: false,
            homepage: false
        };
        this.resetForm();
    }

    getNewsItem(id: string) {
        this.defaultService.meldpuntNewsitemsGetNewsitemDetail(id).subscribe((next) => {
            this.data = {
                ...next,
                titel: next.titel,
                inhoud: next.inhoud,
                afbeeldingUrl: next.afbeelding,
                afbeelding: undefined
            };
            this.resetForm();
        });
    }

    changeImage($event: File) {
        this.FORM.afbeelding = $event;
    }

    resetForm() {
        if (this.canExit()) this.FORM = structuredClone(this.data);
        this.changes = false;
        this.loading = false;
        this.ready = true;
    }

    async deleteItem() {
        this.submitting = true;

        this.defaultService.meldpuntNewsitemsDeleteNewsitem(this.id).subscribe({
            next: (next) => {
                this.changes = false;
                this.submitting = false;
                this.activeModal.close();
            },
            error: (error) => {
                this.submitting = false;
                this.validation = error.error.details;
            }
        });
    }

    async submit() {
        this.submitting = true;
        const updateOrCreatePayload: MeldpuntNewsitemPayload = {
            id: this.mode === 'create' ? undefined : this.id,
            titel: this.FORM?.titel,
            inhoud: this.FORM?.inhoud,
            gepubliceerd: this.FORM?.gepubliceerd,
            homepage: this.FORM?.homepage
        };

        try {
            const result = await lastValueFrom(
                this.defaultService.meldpuntNewsitemsUpdateOrCreateNewsitem(updateOrCreatePayload)
            );
            if (this.FORM?.afbeelding) {
                await lastValueFrom(this.defaultService.meldpuntNewsitemsUploadImage(this.FORM?.afbeelding, result.id));
            } else {
                await lastValueFrom(this.defaultService.meldpuntNewsitemsDeleteImage(this.id));
            }

            if (this.mode === 'create')
                this.toastr.success('Nieuwsitem werd succesvol aangemaakt', 'Nieuwsitem aangemaakt');
            else this.toastr.success('Nieuwsitem werd succesvol gewijzigd', 'Nieuwsitem gewijzigd');

            this.submitting = false;
            this.changes = false;
            this.submitting = false;
            this.activeModal.close();
        } catch (error) {
            console.log('error', error);
            this.validation = error.error.details;
            this.submitting = false;
        }
    }

    formChange() {
        this.changes = true;
    }

    dismiss() {
        if (this.canExit()) {
            this.activeModal.dismiss();
        }
    }

    canExit(): boolean {
        if (
            (!this.changes && !this.submitting) ||
            confirm('Er zijn niet-opgeslagen wijzigingen. Ben je zeker dat je wil annuleren?')
        ) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
