<nav [class.collapsed]="collapsed" [class.collapsed-animation-done]="collapsedAnimationDone">
    <button class="button-collapse" (click)="toggleCollapsed()"><i class="im-icon" [class.im-icon-chev-left]="!collapsed" [class.im-icon-chev-right]="collapsed"></i></button>
    <div class="nav-head">
        <a [routerLink]="['/'+ConfigService.getConfig().homeRoute]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="logo" [class.rijveilig]="rijveilig" >
            @if (appCode === 'meldpunt') {
                <img class="hide-collapsed" [class.giv]="appCode === 'rijveilig'" src="/assets/img/misc/logo-{{appCode}}.png" alt="">
                <img class="show-collapsed" [class.giv]="appCode === 'rijveilig'" src="/assets/img/misc/logo-collapsed-{{appCode}}.png" alt="">
            } @else {
                <img class="hide-collapsed" [class.giv]="appCode === 'rijveilig'" src="/assets/img/misc/logo-{{appCode}}.svg" alt="">
                <img class="show-collapsed" [class.giv]="appCode === 'rijveilig'" src="/assets/img/misc/logo-collapsed-{{appCode}}.svg" alt="">
            }
        </a>
    </div>
    <div class="nav-body">
        <ul class="d-flex flex-column navigation">
            <!-- kortingscodes -->
            <ng-container *ngIf="appCode == 'discountcodes'">
                <li>
                    <a [routerLink]="['/kortingscodes']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-discount-tag"></i><span>Kortingscodes</span></a>
                </li>
            </ng-container>
            <!-- nieuwsbrieven -->
            <ng-container *ngIf="appCode == 'newsletters'">
                <li>
                    <a [routerLink]="['/dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-dashboard"></i><span>Dashboard</span></a>
                </li>
                <li>
                    <a [routerLink]="['/nieuwsbrieven']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-mail"></i><span>Nieuwsbrieven</span></a>
                </li>
                <li>
                    <a [routerLink]="['/selecties']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-search"></i><span>Selecties</span></a>
                </li>
            </ng-container>
            <!-- Rijveilig -->
            <ng-container *ngIf="appCode == 'rijveilig'">
                <li>
                    <a [routerLink]="['/werkzame-stoffen']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-lab"></i><span>Werkzame stoffen</span></a>
                </li>
                <li>
                    <a [routerLink]="['/nieuws']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-newspaper-2"></i><span>Nieuws</span></a>
                </li>
                <li>
                    <a [routerLink]="['/handige-tips']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-lamp"></i><span>Handige tips</span></a>
                </li>
                <li>
                    <a [routerLink]="['/veelgestelde-vragen']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-FAQ"></i><span>Veelgestelde vragen</span></a>
                </li>
                <li>
                    <a [routerLink]="['/paginas']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-catalog"></i><span>Pagina's</span></a>
                </li>
                <li>
                    <a [routerLink]="['/contact']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-comment"></i><span>Contact</span></a>
                </li>
                <li>
                    <a [routerLink]="['/toolkit']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-archive"></i><span>Toolkit</span></a>
                </li>
                <li>
                    <a [routerLink]="['/banner']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-bell"></i><span>Banner</span></a>
                </li>
            </ng-container>
            <!-- Meldpunt -->
            <ng-container *ngIf="appCode == 'meldpunt'">
                <li>
                    <a [routerLink]="['meldpunt', 'dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-dashboard"></i><span>Dashboard</span></a>
                </li>
                <li>
                    <a [routerLink]="['meldpunt', 'meldingen']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-member-card"></i><span>Meldingen</span></a>
                </li>
                <li>
                    <a [routerLink]="['meldpunt', 'nieuws']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-newspaper-2"></i><span>Nieuws</span></a>
                </li>
                <li>
                    <a [routerLink]="['meldpunt', 'paginas']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-catalog"></i><span>Pagina's</span></a>
                </li>
                <li>
                    <a [routerLink]="['meldpunt', 'popups']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-template"></i><span>Popups</span></a>
                </li>
                <li>
                    <a [routerLink]="['meldpunt', 'medicijnen']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-pills"></i><span>Medicijnen</span></a>
                </li>
            </ng-container>
        </ul>
    </div>
</nav>

<div [class.collapsed]="collapsed" class="nav-spacer"></div>