import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }

    public getParam(param: string): string {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param];
    }
    public addParam(param: string, value: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] == null || object[key] == undefined || object[key] == '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }

    public downloadLink(link: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = link;
        a.setAttribute('download', null);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);
    }

    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastr.info('', successMessage);
        }
    }

    public fillHttp(url) {
        if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = 'http://' + url;
        }
        return url;
    }

    public getUUID(parts?: number): string {
        parts = parts || 4;
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }
}
