<div class="page">
    <!-- top bar -->
    <section class="top-bar">
        <!-- title -->
        <h1 class="">Popups <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>

        <div class="d-flex align-items-center">
            <!-- overview -->
            <a [href]="appOverviewUrl" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <div class="d-flex justify-content-between">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap mr-xs">
                    <!-- search bar -->
                    <app-search-input
                        [model]="SEARCH?.term" 
                        (modelChange)="SEARCH.term = $event" 
                        [placeholder]="'In popups zoeken...'"
                        maxWidth="240"
                        (enter)="search()"
                        (clear)="search(true)"
                    ></app-search-input>
                </div>
                <!-- advanced search button -->
                <button (click)="search(true)" class="button-link underline pt-xxs pb-xxs">Herstellen</button>
            </div>
            <div class="search-sub-wrap d-flex">
                <div class="d-flex align-items-start">
                    <div class="tags" *ngIf="SEARCHED?.length">
                        <div class="tag d-inline-flex align-items-center" *ngFor="let tag of SEARCHED">
                            <span><span class="title">{{tag.title}}: </span><span class="value">{{tag.value}}</span></span>
                            <i class="im-icon im-icon-close-round-filled" (click)="search(true)"></i>
                        </div>
                    </div>
                    <!-- reset search button -->
                    <button *ngIf="SEARCHED?.length" (click)="search(true)" class="button-link underline">Alle filters wissen</button>
                </div>
            </div>
        </div>
        <app-button classes="primary-default" icon="plus-round" text="Popup toevoegen" [disabled]="false" [submitting]="false" (emitClick)="openDetail(undefined, 'create')"></app-button>
    </div>

   <!-- table -->
   <div class="table-wrap">
    <app-table
            emptyState="Geen resultaten"
            [heads]="tableHeads" 
            [data]="data" 
            [sort]="SORT"
            [RPP]="RPP"
            [startRow]="startRow"
            [totalRows]="totalRows"
            [loading]="loading"
            [ready]="ready"
            (sortChange)="setSort($event.code, $event.dir)"
            (clickData)="openDetail($event.item)"
            (clickAction)="actionClick($event.item, $event.action)"
            (startRowChange)="setStartRow($event)"
        ></app-table>
   </div>
</div>