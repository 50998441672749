<div class="page">
    <!-- top bar -->
    <section class="top-bar">
        <!-- title -->
        <h1 class="">Pagina's <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready"></span></h1>

        <div class="d-flex align-items-center">
            <!-- overview -->
            <a href="{{configService.getConfig().appOverviewUrl}}" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <div class="d-flex justify-content-between top-bar">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap mr-xs">
                    <!-- search bar -->
                    <app-search-input
                        [model]="SEARCH?.term" 
                        (modelChange)="SEARCH.term = $event" 
                        [placeholder]="'In paginas zoeken...'"
                        maxWidth="240"
                        (enter)="search()"
                        (clear)="search(true)"
                    ></app-search-input>
                    </div>
                <!-- advanced search button -->
                <button (click)="search(true)" class="button-link underline pt-xxs pb-xxs">Herstellen</button>
            </div>
            <div class="search-sub-wrap d-flex">
                <div class="d-flex align-items-start">
                    <div class="tags" *ngIf="SEARCHED?.length">
                        <div class="tag d-inline-flex align-items-center" *ngFor="let tag of SEARCHED">
                            <span><span class="title">{{tag.title}}: </span><span class="value">{{tag.value}}</span></span>
                            <i class="im-icon im-icon-close-round-filled" (click)="search(true)"></i>
                        </div>
                    </div>
                    <!-- reset search button -->
                    <button *ngIf="SEARCHED?.length" (click)="search(true)" class="button-link underline">Alle filters wissen</button>
                </div>
            </div>
        </div>

    </div>

    <!-- table -->
    <div class="table-wrap">
        <app-table
            [heads]="tableHeads" 
            [data]="data" 
            [loading]="loading"
            [ready]="ready"
            emptyState="Geen resultaten"
            (clickData)="openDetailPage('view', $event.item)"
            (clickAction)="actionClick($event.item, $event.action)"
        ></app-table>
    </div>

</div>