<div class="page">

    <!-- top bar -->
    <section class="top-bar">
        <!-- search left -->
        <h1 class="">Dashboard</h1>
        <div class="d-flex align-items-center">
            <!-- overview -->
            <a [href]="appOverviewUrl" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    @if (!ready) {
        <app-spinner [centerVertically]="true"></app-spinner>
    } @else {
        <section>
            <div class="dashboard-title">
                <div class="icon-wrap">
                    <i class="im-icon im-icon-member-card title-icon"></i>
                    <span class="count-indicator">{{data?.length}}</span>
                </div>
                <h2>Goed te keuren meldingen</h2>
            </div>
            <table class="table--dashboard table--detail--dash table--detail--dash--buttons saving-pre" [class.saving]="loading">
                <tr>
                    <th width="10%">Ervaring</th>
                    <th width="30%">Medicijn</th>
                    <th width="45%">Melding</th>
                    <th width="15%">Aangemaakt op</th>
                    <th width="1%"></th>
                </tr>

                @for (item of data; track $index) {
                    @if ($index < showMax || showAll) {
                        <tr>
                            <td>
                                <i class="{{item.ervaringIndicator?.classList}}" [ngStyle]="{'background-image': 'url(' + item.ervaringIndicator?.iconPath + ')'}" ></i>
                            </td>
                            <td>
                                <span class="{{item.medicijn?.classList}}">
                                    {{item.medicijn.value}}
                                </span>
                            </td>
                            <td>
                                <span class="{{item.ervaringDescr?.classList}}">
                                    {{item.ervaringDescr.value}}
                                </span>
                            </td>
                            <td>
                                <span class="{{item.createTS?.classList}}">
                                    {{item.createTS.value}}
                                </span>
                            </td>
                            <td class="td--buttons">
                                <app-button classes="primary-default button-size--xs ml-auto" text="Bekijk" (emitClick)="openDetail(item.id)"></app-button>
                            </td>
                        </tr>
                    }
                }

                
                <!-- show all -->
                <tr class="tr--show-all" *ngIf="!showAll && totalRows > showMax">
                    <td colspan="5">
                        <div class="pad" (click)="showAll = true">
                            <button class="button-link">
                                <i class="im-icon im-icon-plus icon-mr-xs"></i>
                                <span class="underline">{{totalRows - showMax}} andere meldingen</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </section>
    }
</div>