<span class="close-modal-outside"><i (click)="dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">

    @if (!ready) {
        <app-spinner [centerVertically]="true"></app-spinner>
    } @else {
        <div class="d-flex detail-modal">

            <!-- start form -->
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
                @if (loading) {
                    <div style="height: 100%;">
                        <app-spinner [centerVertically]="true"></app-spinner>
                    </div>
                }
               
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <h1 style="min-height: 36px;">
                        @if (mode === 'create') {
                            Nieuwsitem toevoegen
                        } @else if (mode === 'edit') {
                            Nieusitem bewerken
                        } @else {
                            Nieuwsitem
                        }
                    </h1>

                    <div class="h-100 d-flex align-items-center">
                        @if (mode === 'create') {
                            <button class="button tertiary" (click)="dismiss()" [class.disabled]="saving"><span>Annuleren</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                        } @else if(mode === 'view') {
                            <button class="button tertiary ml-xs" (click)="mode = 'edit'" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                            <div class="position-relative ml-xs" appClickOutside (clickOutside)="confirmDelete = false">
                                <button class="button tertiary delete-red" (click)="confirmDelete = true" [class.disabled]="submitting"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="deleteItem();" (cancel)="confirmDelete = false" [open]="confirmDelete" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>

                        } @else {
                            <button class="button tertiary" (click)="mode = 'view'; resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                        }
                    </div>
                   
                </div>



                <div class="detail-modal-body">
                    <!-- content -->
                    <section class="d-flex flex-column gap-32">
                        <div class="display-content news-content-row">
                            @if (mode === 'view') {
                                <div class="display-label">Titel</div>
                                <div class="display-value d-flex flex-row">
                                    <span class="page-content" [innerHTML]="data?.titel | display | safe : 'html'"></span>
                                </div>
                            } @else {
                                <app-field-text 
                                    [model]="FORM?.titel" 
                                    (modelChange)="FORM.titel = $event; formChange();" 
                                    [label]="'Titel'"
                                    [expanded]="true"
                                    [error]="validation?.titel">
                                </app-field-text>
                            }
                        </div>
                        <div class="display-content news-content-row">
                            @if (mode === 'view') {
                                <div class="display-label">Inhoud</div>
                                <div class="display-value d-flex flex-row">
                                    <span class="page-content" [innerHTML]="data?.inhoud | display | safe : 'html'"></span>
                                </div>
                            } @else {
                                <app-field-richtext 
                                    [model]="FORM?.inhoud" 
                                    (modelChange)="FORM.inhoud = $event; formChange();" 
                                    [label]="'Inhoud'"
                                    [expanded]="true"
                                    [error]="validation?.inhoud">
                                </app-field-richtext>
                            }
                        </div>
                        <div class="display-content news-content-row">
                            <!-- FILE UPLOAD -->
                            @if (mode === 'view') {
                                <div class="display-label">Afbeelding</div>
                                <div class="display-value d-flex flex-row">
                                    @if (data?.afbeeldingUrl) {
                                        <img [src]="data?.afbeeldingUrl" alt="nieuwsberichtfoto">
                                    } @else {
                                        <span *ngIf="!data.afbeeldingUrl">-</span>
                                    }
                                </div>
                            } @else {
                                <app-field-image
                                    (sendFile)="changeImage($event); formChange();"
                                    [label]="'Afbeelding'"
                                    [file]="FORM.afbeelding"
                                    [url]="FORM.afbeeldingUrl"
                                    [error]="validation?.afbeelding"
                                    [optionalString]="'.jpg, .png, .JPEG'">
                                </app-field-image>
                            }
                        </div>
                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Gepubliceerd</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{data?.gepubliceerd | yesNo | display}}</span>
                                </div>
                            } @else {
                                <app-field-radios 
                                    class="d-block"
                                    label="Gepubliceerd"
                                    [model]="FORM.gepubliceerd ? 'yes' : 'no'"
                                    (modelChange)="FORM.gepubliceerd = ($event === 'yes' ? true : false); formChange();"
                                    [error]="validation?.gepubliceerd"
                                    [options]="yesNoOptions"
                                    [optionWidth]="80"
                                    [optionColumnWidth]=""
                                ></app-field-radios>
                            }
                        </div>
                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Toon op homepage</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{data?.homepage | yesNo | display}}</span>
                                </div>
                            } @else {
                                <app-field-radios 
                                    class="d-block"
                                    label="Toon op homepage"
                                    [model]="FORM.homepage ? 'yes' : 'no'"
                                    (modelChange)="FORM.homepage = ($event === 'yes' ? true : false); formChange();"
                                    [error]="validation?.homepage"
                                    [options]="yesNoOptions"
                                    [optionWidth]="80"
                                ></app-field-radios>
                            }
                        </div>
                    </section>
        
                </div>


                @if (id) {
                    <app-history
                        [createTS]="data.createTS"
                        [createUsername]="data.createUsername"
                        [editTS]="data.editTS"
                        [editUsername]="data.editUsername"
                    ></app-history>
                }

            </div>
        </div>
    }
    
</div>